import { Container, Row, Col, Tab, Nav } from "react-bootstrap";
import { ProjectCard } from "./ProjectCard";
import projImg1 from "../assets/img/Ktxpy.png";
import projImg2 from "../assets/img/Chatting.png";
import projImg3 from "../assets/img/Unity.png";
import projImg4 from "../assets/img/Zen.png";
import colorSharp2 from "../assets/img/color-sharp2.png";
import 'animate.css';
import TrackVisibility from 'react-on-screen';

export const Projects = () => {

  const current_projects = [
    {
      title: "Ktxpy",
      description: "IDE for Python3",
      imgUrl: projImg1,
      link : "https://github.com/Psicodes/ktxpy"
    },
    {
      title: "Chatting App (private)",
      description: "An chatting app for a game dev studio",
      imgUrl: projImg2,
      link : "#"
    },
    {
      title: "Fps Controller (private)",
      description: "Full body advanced fps controller",
      imgUrl: projImg3,
      link : "#"
    },
  ];
  const upcoming_projects = [
    {
      title: "Zen",
      description: "Time management & focus",
      imgUrl: projImg4,
      link : "#"
    }
  ];
  

  return (
    <section className="project" id="projects">
      <Container>
        <Row>
          <Col size={12}>
            <TrackVisibility>
              {({ isVisible }) =>
              <div className={isVisible ? "animate__animated animate__fadeIn": ""}>
                <h2>Projects</h2>
                <p>Some Projects created by Me. Have a Look </p>
                <Tab.Container id="projects-tabs" defaultActiveKey="first">
                <Nav variant="pills" className="nav-pills mb-5 justify-content-center align-items-center flex-wrap" id="pills-tab">
    <Nav.Item className="text-center">
        <Nav.Link eventKey="first" className="small-text">Projects</Nav.Link>
    </Nav.Item>
    <Nav.Item className="text-center">
        <Nav.Link eventKey="second" className="small-text">Upcoming</Nav.Link>
    </Nav.Item>
    <Nav.Item className="text-center">
        <Nav.Link eventKey="third" className="small-text">Code</Nav.Link>
    </Nav.Item>
</Nav>

<style jsx>{`
    .small-text {
        font-size: 0.75rem; /* Adjust font size for small screens */
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis; /* Add ellipsis to indicate overflow */
        padding: 0.2rem 0.5rem; /* Add padding to avoid overlapping */
    }
`}</style>


                  <Tab.Content id="slideInUp" className={isVisible ? "animate__animated animate__slideInUp" : ""}>
                    <Tab.Pane eventKey="first">
                      <Row>
                        {
                          current_projects.map((project, index) => {
                            return (
                              <ProjectCard
                                key={index}
                                {...project}
                                />
                            )
                          })
                        }
                      </Row>
                    </Tab.Pane>
                    <Tab.Pane eventKey="second">
                    <Row>
                        {
                          upcoming_projects.map((project, index) => {
                            return (
                              <ProjectCard
                                key={index}
                                {...project}
                                />
                            )
                          })
                        }
                      </Row>
                    </Tab.Pane>
                    <Tab.Pane eventKey="third">
                      <p>Source code of public both completed and upcoming can be found on <a href="https:/github.com/Psicodes">Github</a>. but the code for private projects cannot be shared for obvious reasons if you really want to know about them you can contact me.</p>
                    </Tab.Pane>
                  </Tab.Content>
                </Tab.Container>
              </div>}
            </TrackVisibility>
          </Col>
        </Row>
      </Container>
      <img className="background-image-right" src={colorSharp2}></img>
    </section>
  )
}

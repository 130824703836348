import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';

export const Warning = () => {
    return (
        <div className="alert alert-warning text-center d-flex flex-column justify-content-center" role="alert">
            <div>
                <FontAwesomeIcon icon={faExclamationTriangle} /> Warning: You are viewing this page on Instagram.
            </div>
            <p className="small" style={{color: 'inherit', fontSize: '0.75em'}}>This may cause problems. Click on <strong>Three Dots</strong> and click <strong>Open In Browser</strong> for better experience.</p>
        </div>
    );
};